import styled from 'styled-components';
import media from '../../shared/media';

export const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px 80px;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr/1fr;
`;

export const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export const FormRow = styled.div`
  display: flex;
  column-gap: 24px;
  flex-direction: column;

  ${media.tablet} {
    flex-direction: row;
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 18px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.textColor};
`;

export const Input = styled.input`
  display: block;
  padding: 16px 20px;
  width: 100%;
  font-size: 18px;
  color: ${({theme}) => theme.colors.textColor};
  background-color: transparent;
  outline: none;
  border: 2px solid ${({theme}) => theme.colors.lightGray};

  &:focus {
    border-color: ${({theme}) => theme.colors.accent};
  }
`;

export const Textarea = styled.textarea`
  display: block;
  padding: 16px 20px;
  width: 100%;
  font-size: 18px;
  background-color: transparent;
  color: ${({theme}) => theme.colors.textColor};
  outline: none;
  font-family: 'Source Sans Pro', sans-serif;
  border: 2px solid ${({theme}) => theme.colors.lightGray};

  &:focus {
    border-color: ${({theme}) => theme.colors.accent};
  }
`;

export const Button = styled.button`
  padding: 16px 20px;
  width: 100%;
  display: block;
  font-size: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.018em;
  font-weight: 700;
  background-color: transparent;
  border: 2px solid ${({theme}) => theme.colors.textColor};
  color: ${({theme}) => theme.colors.textColor};
  outline: none;
  cursor: pointer;
  transition: 0.1s ease-in-out;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.textColor};
    color: ${({theme}) => theme.colors.lightGray};
  }
`;

export const Message = styled.h2`
  color: ${({theme}) => theme.colors.textColor};
`;
