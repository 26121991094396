import React from 'react';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import CtaSection from '../components/CtaSection';
import {graphql} from 'gatsby';
import SectionMapper from '../components/SectionMapper/SectionMapper';
import ContactForm from '../components/ContactForm';

import {buildNavigationMenu} from '../utils/navigation';

const PageContactTemplate = ({pageContext, data}) => {
  const {generalSettings} = pageContext;
  const {sections, title, slug, metaTitle, metaDescription, metaKeywords} =
    data.page;

  const companyAddress = {
    address: generalSettings.companyAddress,
    email: generalSettings.companyEmail,
  };

  const metaContent = {
    metaTitle,
    metaDescription: metaDescription?.childMarkdownRemark?.html,
    metaKeywords,
  };

  const headerNavigation = buildNavigationMenu(
    generalSettings.headerMenu.items
  );
  const footerNavigation = buildNavigationMenu(
    generalSettings.footerMenu.items
  );

  return (
    <Layout
      headerNavigation={headerNavigation}
      footerNavigation={footerNavigation}
      title={title}
      companyAddress={companyAddress}
      metaContent={metaContent}
      slug={slug}
    >
      {(sections || []).map((section, key) => {
        if (section.__typename === 'ContentfulHero') {
          return (
            <Hero key={key}>
              <Hero.Title
                dangerouslySetInnerHTML={{
                  __html: section.title.childMarkdownRemark.html,
                }}
              />
              {section.content && (
                <Hero.Content
                  dangerouslySetInnerHTML={{
                    __html: section.content.childMarkdownRemark.html,
                  }}
                  isContactPage={pageContext.slug.includes('contact')}
                />
              )}
            </Hero>
          );
        }
        return null;
      })}
      <ContactForm />
      {(sections || []).map((section, key) => {
        if (section.__typename === 'ContentfulCtaSection') {
          return (
            <CtaSection
              key={key + 10}
              title={section.ctaSectionTitle}
              actionLink={section.actionLink}
              actionText={section.actionText}
            />
          );
        }
      })}
    </Layout>
  );
};

export default PageContactTemplate;

export const query = graphql`
  query contactPage($slug: String!) {
    page: contentfulPage(slug: {eq: $slug}) {
      title
      slug
      sections {
        ... on ContentfulHero {
          __typename
          ...Hero
        }
        ... on ContentfulSection {
          __typename
          ...Section
        }
        ... on ContentfulClientsSection {
          __typename
          ...ClientsSection
        }
        ... on ContentfulInsightsSection {
          __typename
          ...InsightsSection
        }
        ... on ContentfulCtaSection {
          __typename
          ...CtaSection
        }
      }
      metaTitle
      metaDescription {
        childMarkdownRemark {
          html
        }
      }
      metaKeywords
    }
  }
`;
