import React, {useCallback, useState} from 'react';
import axios from 'axios';

import * as Styled from './ContactForm.styles';
import {useMemo} from 'react';

const ContactForm = () => {
  const portalId = '22560075';
  const formGuid = '74d8cfd0-87a4-4b10-aa06-8ddde67cb803';

  const [formData, setFormData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isValid = useMemo(() => {
    return (
      formData.name &&
      formData.lastName &&
      formData.email &&
      formData.companyName &&
      formData.message
    );
  }, [formData]);

  const handleChange = useCallback((event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const data = {
        portalId,
        formGuid,
        fields: [
          {
            name: 'firstName',
            value: formData.name,
          },
          {
            name: 'lastName',
            value: formData.lastName,
          },
          {
            name: 'email',
            value: formData.email,
          },
          {
            name: 'company',
            value: formData.companyName,
          },
          {
            name: 'message',
            value: formData.message,
          },
        ],
      };

      try {
        setIsSubmitting(true);
        await axios.post(
          `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
          data,
          {headers: {'Content-Type': 'application/json'}}
        );
        setIsSubmitting(false);
        setIsSuccess(true);
      } catch (e) {
        setIsSubmitting(false);
        setIsSuccess(false);
      }
    },
    [formData]
  );

  if (!isSubmitting && isSuccess) {
    return (
      <Styled.Wrapper>
        <Styled.Message>
          Thank you for your message! We'll contact you soon.
        </Styled.Message>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Form onSubmit={handleSubmit}>
        <Styled.FormRow>
          <Styled.FormGroup>
            <Styled.Label htmlFor="name">First Name</Styled.Label>
            <Styled.Input
              id="name"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
              placeholder=""
            />
          </Styled.FormGroup>
          <Styled.FormGroup>
            <Styled.Label htmlFor="lastName">Last Name</Styled.Label>
            <Styled.Input
              id="lastName"
              name="lastName"
              required
              value={formData.lastName}
              onChange={handleChange}
              placeholder=""
            />
          </Styled.FormGroup>
        </Styled.FormRow>
        <Styled.FormGroup>
          <Styled.Label htmlFor="email">Email</Styled.Label>
          <Styled.Input
            type="email"
            id="email"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
            placeholder=""
          />
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.Label htmlFor="companyName">
            Company Name
          </Styled.Label>
          <Styled.Input
            id="companyName"
            name="companyName"
            required
            value={formData.companyName}
            onChange={handleChange}
            placeholder=""
          />
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.Label htmlFor="message">
            How can we help?
          </Styled.Label>
          <Styled.Textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            rows={8}
          ></Styled.Textarea>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.Button disabled={!isValid || isSubmitting}>
            {isSubmitting ? 'Please wait' : 'Submit'}
          </Styled.Button>
        </Styled.FormGroup>
      </Styled.Form>
    </Styled.Wrapper>
  );
};

export default ContactForm;
